import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import ResetIcon from '../../../../assets/svg/reset-icon.svg'
import { setScoreButtonColorStyle } from '../../../../../va-corejs-v3/utils'

const classNames = require('classnames')

export class ForecastScoreButtons extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      min: 0,
      max: 0,
      defaultValue: 0,
      halfPoints: false,
    }
  }

  handleHalfPointsClicked = (e, halfPoints) => {
    e.stopPropagation()
    this.setState({ halfPoints })
  }

  componentDidMount = async () => {
    const { demerit, environment, criterionInstance } = this.props
    const halfPoints = criterionInstance.score % 1 !== 0

    if (!demerit) {
      this.setState({
        min: environment.config.min_score,
        max: environment.config.max_score,
        defaultValue: environment.config.default_score,
        halfPoints,
      })
    } else {
      this.setState({
        min: environment.config.demerit_min_score,
        max: environment.config.demerit_max_score,
        defaultValue: environment.config.demerit_default_score,
        halfPoints,
      })
    }
  }

  render() {
    const { min, max, defaultValue, halfPoints } = this.state
    const {
      environment,
      criterionInstance,
      scoringTree,
      device,
      demerit,
      updateScoreValue,
      onScoreButtonsClick,
      processSyncQueue,
    } = this.props

    async function handleScoreValueClicked(e) {
      e.stopPropagation()
      onScoreButtonsClick()
      const scoreValue = e.target.getAttribute('scorevalue')
      await updateScoreValue(scoringTree, criterionInstance.node_definition_id, scoreValue, false, 'forecast')
      if (device.isOnline) {
        processSyncQueue()
      }
    }

    async function handleResetScoreClicked(e) {
      e.stopPropagation()

      await updateScoreValue(scoringTree, criterionInstance.node_definition_id, defaultValue, true, 'forecast')
      if (device.isOnline) {
        processSyncQueue()
      }
    }
    const Buttons = () => {
      const buttons = []
      const start = parseFloat(halfPoints ? parseFloat(min) + 0.5 : parseFloat(min))
      const end = parseFloat(halfPoints ? parseFloat(max) + 0.5 : parseFloat(max))

      for (let i = start; i <= end; i += 1) {
        const buttonScore = i === 5.5 ? 5 : i
        const buttonSelected =
          criterionInstance.forecast &&
          parseFloat(criterionInstance.forecast.score) === parseFloat(buttonScore) &&
          !criterionInstance.forecast.is_default

        buttons.push(
          <div
            className={classNames({
              ...setScoreButtonColorStyle(demerit, buttonScore, buttonSelected),
            })}
            key={i}
            role="button"
            tabIndex={0}
            scorevalue={buttonScore}
            onClick={handleScoreValueClicked}
            onKeyPress={handleScoreValueClicked}
          >
            {buttonScore}
          </div>
        )
      }
      return buttons
    }

    return (
      <div
        className={classNames({
          sctree_rp_criterion_score_buttons: true,
        })}
      >
        <Buttons />
        <div
          className={classNames({
            sctree_rp_criterion_score_buttons_divider: true,
          })}
        />
        {environment.config.half_points_enabled && (
          <div
            className={classNames({
              sctree_rp_criterion_score_btn: true,
              sctree_rp_criterion_score_button_half: true,
              sctree_rp_criterion_score_btn_selected: halfPoints,
              theme_color: true,
            })}
            role="button"
            tabIndex={0}
            onClick={e => {
              this.handleHalfPointsClicked(e, !halfPoints)
            }}
            onKeyPress={e => {
              this.handleHalfPointsClicked(e, !halfPoints)
            }}
          >
            .5
          </div>
        )}
        <div
          className={classNames({
            sctree_rp_criterion_score_btn: true,
            sctree_rp_criterion_score_button_reset: true,
            theme_svg: true,
          })}
          role="button"
          tabIndex={0}
          onClick={e => {
            handleResetScoreClicked(e)
            this.setState({ halfPoints: false })
          }}
          onKeyPress={e => {
            handleResetScoreClicked(e)
            this.setState({ halfPoints: false })
          }}
        >
          <ResetIcon />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    device: state.device,
  }
}

export default connect(mapStateToProps, actionCreators)(ForecastScoreButtons)
