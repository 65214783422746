import React from 'react'
import { connect } from 'react-redux'

import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
  Legend,
  LabelList,
  Line,
  Tooltip,
} from 'recharts'

import {
  getGlobalAverageOfSegment,
  getBestOfSegmentPerFamily,
  getBestOfAllPerFamily,
} from '../../../../../../va-corejs-v3/actions/charts'

import LoadingBar from '../../../../loading_bar/index.js'
import ChartPopup from '../_parts/chartPopup'

import * as actionCreators from '../../../../../store/actions'
import nodeDefinitionTypeMap from '../../../helper/nodeDefinitionTypeMap'
import scorePanelPropsMap from '../../../helper/scorePanelPropsMap'
import analyticsRefsTypesMap from '../../../helper/analyticsRefsTypesMap'
import scopePropsMap from '../../../../scoring/_parts/helper/scopePropsMap'

import {
  getProductName,
  calculatePopupChartHeight,
  calulateTickCharactersPerLine,
  doCalculationForAnalytics,
} from './common/utils'
import { splitTextToLines } from '../../../../../../va-corejs-v3/utils'

const uuidv4 = require('uuid/v4')
const classNames = require('classnames')

export class FamilyPerformanceOverviewChart extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.chartRef = React.createRef()

    this.state = {
      loading: false,
      showPopup: false,
      benchmarkExists: false,
      benchmarkSelected: false,
      families: ['appearance', 'usability', 'robustness', 'workmanship'],
      productName: '',
      data: [],
      dataLabels: [],
      target: 0,
      barColors: {
        initial: '',
        detailed: '',
      },
      settings: {
        chartContainerHeight: 214,
        xAxisDataKey: 'cFamily',
        xAxisHeight: 30,
        colors: {
          exterior: {
            initial: '#695384',
            detailed: '#BEAFD3',
          },
          interior: {
            initial: '#225966',
            detailed: '#4698AE',
          },
          cargo: {
            initial: '#B94A29',
            detailed: '#D8B0A3',
          },
          benchmark: {
            initial: '#00BBFF',
            detailed: '#aaDDFF',
          },
        },
      },
    }
  }

  componentDidMount = async () => {
    this._isMounted = true
    this.initComponent()
  }

  componentWillUnmount() {
    this._isMounted = false
    this.setState = () => {}
  }

  filterCriterionPerFamilyAndCTypeAndDoCalcultion = async (ctype, fNodes) => {
    const { family, scoringTree, environment } = this.props
    const { nodeDefsObj } = scoringTree
    const { nodes } = scoringTree.scoring
    const filteredNodes = fNodes ? { ...fNodes } : { ...nodes }

    const idList = Object.keys(filteredNodes)
    const nodeToExclude = []
    for (let i = 0; i !== idList.length; i += 1) {
      const nodeId = idList[i]
      const def = nodeDefsObj[nodeId]
      const { type } = def
      if (type === nodeDefinitionTypeMap.criterion) {
        const itemDef = nodeDefsObj[def.parent_id]
        const subFamDef = nodeDefsObj[itemDef.parent_id]
        const famDef = nodeDefsObj[subFamDef.parent_id]
        const familyDefName = famDef.name[environment.defaultLang]

        if (
          (family === scorePanelPropsMap.exterior && familyDefName.indexOf('Exterior') !== 0) ||
          (family === scorePanelPropsMap.interior && familyDefName.indexOf('Interior') !== 0) ||
          (family === scorePanelPropsMap.cargo && familyDefName.indexOf('Cargo') !== 0)
        ) {
          nodeToExclude.push(nodeId)
        }

        const nodeType = def.criterion_template.type.slug
        if (nodeType !== ctype) {
          nodeToExclude.push(nodeId)
        }
      }
    }

    for (let i = 0; i !== nodeToExclude.length; i += 1) {
      const nodeId = nodeToExclude[i]
      delete filteredNodes[nodeId]
    }

    const filteredNodesWithCalc = await doCalculationForAnalytics(nodeDefsObj, filteredNodes)

    return filteredNodesWithCalc
  }

  recursivelyFilterAndDoCalculation = async (families, trees, fNodes) => {
    if (families.length === 0) return

    const { scoringTree, environment } = this.props
    const { nodeDefsObj } = scoringTree

    const ctype = families.pop()
    const filteredNodes = await this.filterCriterionPerFamilyAndCTypeAndDoCalcultion(ctype, fNodes)

    const children = []
    const nodeDefsArray = Object.values(nodeDefsObj)
    for (let i = 0; i !== nodeDefsArray.length; i += 1) {
      const nodeDef = nodeDefsArray[i]
      if (nodeDef.parent_id === null) {
        children.push(nodeDef.id)
      }
    }

    trees[ctype] = {
      initial: 0,
      detailed: 0,
    }

    for (let i = 0; i !== children.length; i += 1) {
      const child = children[i]
      const node = filteredNodes[child]
      const nodeName = nodeDefsObj[child].name[environment.defaultLang]
      const { percentage } = node
      if (nodeName.indexOf('Initial') === 0) {
        trees[ctype].initial = percentage.toFixed(2)
      } else {
        trees[ctype].detailed = percentage.toFixed(2)
      }
    }

    await this.recursivelyFilterAndDoCalculation(families, trees, fNodes)
  }

  getChartColor = () => {
    const { family } = this.props

    const { settings } = this.state
    const { colors } = settings

    let barColors = {}
    if (family === scorePanelPropsMap.exterior) {
      barColors = colors.exterior
    } else if (family === scorePanelPropsMap.interior) {
      barColors = colors.interior
    } else if (family === scorePanelPropsMap.cargo) {
      barColors = colors.cargo
    }

    this.setStateIfMounted({ barColors })
  }

  initComponent = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree } = this.props
    const { selectedReferenceType } = scoringTree.analytics
    const { props } = scoringTree.scoring

    const productName = await getProductName(props)
    this.setStateIfMounted({ productName })

    const { families } = this.state
    const treesByFamily = {}
    const familiesDuplicate = [...families]

    await this.recursivelyFilterAndDoCalculation(familiesDuplicate, treesByFamily)

    const data = []
    const dataLabels = []

    for (let i = 0; i !== families.length; i += 1) {
      const cType = families[i]
      const typeTree = treesByFamily[cType]
      dataLabels.push(cType)

      const dataObj = {
        cFamily: '',
      }
      if (typeTree.initial > 0) {
        dataObj.initialPerformance = typeTree.initial
      }
      if (typeTree.detailed > 0) {
        dataObj.detailedPerformance = typeTree.detailed
      }

      data.push(dataObj)
    }

    this.setStateIfMounted({ data, dataLabels })
    this.getChartColor()
    this.performActionOnReferenceUpdated(selectedReferenceType)
    await this.updateBenchmarkData()
  }

  setStateIfMounted = state => {
    if (this._isMounted) {
      this.setState(state)
    }
  }

  performActionOnReferenceUpdated = async referenceType => {
    if (referenceType === false || referenceType.indexOf(analyticsRefsTypesMap.default) === 0) {
      this.updateTargetDataByScoreValue(2)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.segment) === 0) {
      const segment = referenceType.replace(`${analyticsRefsTypesMap.segment}_`, '')
      this.updateTargetValueByAverageSegment(segment)
    } else if (referenceType.indexOf(analyticsRefsTypesMap.best_of_segment) === 0) {
      this.updateTargetValueByBestOfSegment()
    } else if (referenceType.indexOf(analyticsRefsTypesMap.best_of_all) === 0) {
      this.updateTargetValueByBestOfAll()
    }
  }

  updateTargetDataByScoreValue = async scoreValue => {
    this.setStateIfMounted({ loading: true })

    const { environment } = this.props
    const { config } = environment

    const calculatedScoreValue = (scoreValue * config.max_product_score) / config.max_score
    const percentageValue = (calculatedScoreValue * 100) / config.max_product_score
    this.setStateIfMounted({ loading: false, target: percentageValue })
  }

  updateTargetValueByAverageSegment = async segment => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree, family } = this.props
    const { environment } = this.props
    const { config } = environment

    const averageHit = await getGlobalAverageOfSegment(scoringTree.scoring.template_id, segment)

    let percentageValue = 0
    if (family === scorePanelPropsMap.exterior) {
      percentageValue = (averageHit.exteriorAverage * 100) / config.max_product_score
    } else if (family === scorePanelPropsMap.interior) {
      percentageValue = (averageHit.interiorAverage * 100) / config.max_product_score
    } else if (family === scorePanelPropsMap.cargo) {
      percentageValue = (averageHit.cargoAverage * 100) / config.max_product_score
    }
    this.setStateIfMounted({ loading: false, target: percentageValue })
  }

  updateTargetValueByBestOfSegment = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree, family } = this.props
    const { environment } = this.props
    const { config } = environment

    const mainSegment = scoringTree.scoring.props.filter(x => x.slug === scopePropsMap.main_segment)[0]

    const bestHit = await getBestOfSegmentPerFamily(scoringTree.scoring.template_id, mainSegment.value.slug, family)

    let percentageValue = 0
    if (family === scorePanelPropsMap.exterior) {
      percentageValue = (bestHit.globalExteriorAverage * 100) / config.max_product_score
    } else if (family === scorePanelPropsMap.interior) {
      percentageValue = (bestHit.globalInteriorAverage * 100) / config.max_product_score
    } else if (family === scorePanelPropsMap.cargo) {
      percentageValue = (bestHit.globalCargoAverage * 100) / config.max_product_score
    }

    this.setStateIfMounted({ loading: false, target: percentageValue })
  }

  updateTargetValueByBestOfAll = async () => {
    this.setStateIfMounted({ loading: true })

    const { scoringTree, family } = this.props
    const { environment } = this.props
    const { config } = environment

    const bestHit = await getBestOfAllPerFamily(scoringTree.scoring.template_id, family)

    let percentageValue = 0
    if (family === scorePanelPropsMap.exterior) {
      percentageValue = (bestHit.globalExteriorAverage * 100) / config.max_product_score
    } else if (family === scorePanelPropsMap.interior) {
      percentageValue = (bestHit.globalInteriorAverage * 100) / config.max_product_score
    } else if (family === scorePanelPropsMap.cargo) {
      percentageValue = (bestHit.globalCargoAverage * 100) / config.max_product_score
    }

    this.setStateIfMounted({ loading: false, target: percentageValue })
  }

  updateBenchmarkData = async () => {
    this.setStateIfMounted({ loading: true })
    const { scoringTree } = this.props
    const { benchmarkNodes, benchmarkId, selectedReferenceType } = scoringTree.analytics
    const { data, dataLabels } = this.state

    if (benchmarkNodes && benchmarkId && selectedReferenceType === analyticsRefsTypesMap.benchmark) {
      this.setStateIfMounted({ loading: true })
      const { families } = this.state
      const treesByFamily = {}
      const familiesDuplicate = [...families]

      await this.recursivelyFilterAndDoCalculation(familiesDuplicate, treesByFamily, benchmarkNodes)

      data.map((currentData, index) => {
        // use of datalabels[] instead of families[]
        // to match benchmark value with performance showed value
        const cType = dataLabels[index]
        const typeTree = treesByFamily[cType]

        currentData.initialBenchmark = typeTree.initial
        currentData.detailedBenchmark = typeTree.detailed

        return currentData
      })

      this.setState({
        loading: false,
        data,
        benchmarkSelected: true,
        benchmarkExists: true,
      })
    } else if (selectedReferenceType === analyticsRefsTypesMap.benchmark) {
      this.setState({
        loading: false,
        benchmarkExists: false,
        benchmarkSelected: true,
      })
    } else
      this.setState({
        loading: false,
        benchmarkExists: false,
        benchmarkSelected: false,
      })
  }

  componentDidUpdate = async prevProps => {
    const { scoringTree } = this.props

    const currRefType = scoringTree.analytics.selectedReferenceType
    const prevRefType = prevProps.scoringTree.analytics.selectedReferenceType

    if (currRefType && currRefType !== prevRefType) {
      await this.performActionOnReferenceUpdated(currRefType)
      await this.updateBenchmarkData()
    }

    const { analytics } = scoringTree
    const { benchmarkNodes } = analytics
    const prevBenchmarkNodes = prevProps.scoringTree.analytics.benchmarkNodes

    if (benchmarkNodes !== prevBenchmarkNodes) {
      await this.updateBenchmarkData()
    }
  }

  handleShowPopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(true)

    this.setStateIfMounted({ showPopup: true })
  }

  handleClosePopupClicked = () => {
    const { updateScrollDisabledStatus } = this.props
    updateScrollDisabledStatus(false)

    this.setStateIfMounted({ showPopup: false })
  }

  render() {
    const { texts } = this.props
    const {
      loading,
      showPopup,
      productName,
      benchmarkExists,
      benchmarkSelected,
      data,
      dataLabels,
      target,
      barColors,
      settings,
    } = this.state
    const { chartContainerHeight, xAxisDataKey, xAxisHeight } = settings
    const { scoringTree } = this.props
    const { benchmarkName, breadcrumb } = scoringTree.analytics

    const customizedLabelName = name => {
      let outputLabelName = false
      outputLabelName = name.indexOf('initial') === 0 ? 'Initial' : 'Detailed'
      outputLabelName = name.includes('Benchmark') ? `${outputLabelName} Benchmark` : outputLabelName
      return outputLabelName
    }

    const renderCustomizedLabelOnFront = props => {
      const { x, y, width, value } = props
      const yPosition = (y || 0) - 15

      return (
        <g>
          <foreignObject className="front-label-container" x={x} y={yPosition} width={width} height={15}>
            <span className="front-label">{Math.round(value) || ''}</span>
          </foreignObject>
        </g>
      )
    }

    const renderLegend = props => {
      const { payload } = props
      return (
        <div className={classNames({ custom_legends_container: true })}>
          {payload.map((entry, index) => (
            <div key={`legend_${index}`} className={classNames({ custom_legend_item: true })}>
              <div
                style={{ backgroundColor: entry.color }}
                className={classNames({
                  custom_legend_marker: true,
                  small_dot_marker: true,
                })}
              />
              <div className={classNames({ custom_legend_value: true })}>{customizedLabelName(entry.value)}</div>
            </div>
          ))}
        </div>
      )
    }

    const renderCustomizedAxisTick = props => {
      const { x, y, payload } = props
      const { index } = payload

      const lineHeigh = showPopup === true ? '12px' : '10px'

      const ticksNumber = dataLabels.length
      const { offsetWidth } = this.chartRef.current.container
      const chartPadding = 70

      const maxLineNumber = showPopup === true ? parseInt(xAxisHeight / 12, 10) - 1 : parseInt(xAxisHeight / 10, 10) - 1

      const charactersPerLine = calulateTickCharactersPerLine(offsetWidth, chartPadding, showPopup, ticksNumber)
      const lines = splitTextToLines(dataLabels[index], charactersPerLine)

      return (
        <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
            {lines.map((line, idx) => (
              <tspan
                key={uuidv4()}
                className={classNames({
                  hidden: idx >= maxLineNumber,
                })}
                x={0}
                dy={lineHeigh}
              >
                {line}
              </tspan>
            ))}
          </text>
        </g>
      )
    }

    const theChart = (width, height) => {
      return (
        <ResponsiveContainer ref={this.chartRef} width={width} height={height}>
          <ComposedChart data={data}>
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey={xAxisDataKey} height={xAxisHeight} tick={renderCustomizedAxisTick} />
            <YAxis type="number" domain={[0, 100]} />
            <Bar dataKey="initialPerformance" fill={barColors.initial} radius={[0, 0, 0, 0]}>
              <LabelList dataKey="initialPerformance" content={renderCustomizedLabelOnFront} />
            </Bar>
            <Bar dataKey="detailedPerformance" fill={barColors.detailed} radius={[0, 0, 0, 0]}>
              <LabelList id="test" dataKey="detailedPerformance" content={renderCustomizedLabelOnFront} />
            </Bar>
            {benchmarkExists && (
              <Line
                connectNulls
                dataKey="initialBenchmark"
                stroke={settings.colors.benchmark.initial}
                style={{ transform: 'translateX(calc(-5% + 4px))' }}
                strokeWidth="2.5"
              />
            )}
            {benchmarkExists && (
              <Line
                connectNulls
                dataKey="detailedBenchmark"
                stroke={settings.colors.benchmark.detailed}
                style={{ transform: 'translateX(calc(5% - 4px))' }}
                strokeWidth="2.5"
              />
            )}
            {benchmarkExists && (
              <Tooltip
                isAnimationActive={false}
                formatter={(value, name) => [Math.round(value), customizedLabelName(name)]}
              />
            )}
            {!benchmarkSelected && <ReferenceLine y={target} stroke="#000000" strokeWidth={2.5} />}

            <Legend content={renderLegend} />
          </ComposedChart>
        </ResponsiveContainer>
      )
    }

    return (
      <>
        {loading === true && (
          <div
            className={classNames({
              chart_loading_container: true,
              family_performance_overview_loading_container: true,
            })}
          >
            <LoadingBar />
          </div>
        )}
        {loading === false && showPopup === false && (
          <div
            className={classNames({
              chart_outer_container: true,
              family_performance_overview_outer_container: true,
            })}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.handleShowPopupClicked()
            }}
            onKeyPress={() => {
              this.handleShowPopupClicked()
            }}
          >
            <div className={classNames({ chart_inner_container: true })}>{theChart('100%', chartContainerHeight)}</div>
          </div>
        )}
        {loading === false && showPopup === true && (
          <ChartPopup
            handleClosePopupClicked={this.handleClosePopupClicked}
            title={texts.percentage_performance_per_criteria_family}
            product={productName}
            breadcrumb={breadcrumb}
            benchmark={benchmarkSelected ? benchmarkName : false}
            width="100%"
            height={calculatePopupChartHeight()}
            theChart={theChart}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    scoringTree: state.scoringTree,
    environment: state.environment,
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, actionCreators)(FamilyPerformanceOverviewChart)
