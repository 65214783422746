import React from 'react'
import { connect } from 'react-redux'

import _ from 'lodash'
import * as actionCreators from '../../../store/actions'
import nodeDefinitionTypeMap from '../helper/nodeDefinitionTypeMap'

import RightPanelHead from './rightPanel/head'
import Item from './rightPanel/item'
import itemViewsMap from '../helper/itemViewsMap'
import { isProjectModeEnabled } from './projectMode/utils'

const classNames = require('classnames')

export class RightPanel extends React.Component {
  componentDidMount = async () => {
    const {
      authentication,
      environment,
      scoringTree,
      getCommentsListFromStorage,
      initializeNewCommentsListForRedux,
      initNewCommentsForecast,
    } = this.props

    const currentLocale = _.find(environment.locales, locale => locale.code === environment.lang)

    await initializeNewCommentsListForRedux(
      scoringTree.scoring.id,
      scoringTree.nodeDefsObj,
      scoringTree.itemsFather.id,
      currentLocale,
      authentication.user
    )

    if (isProjectModeEnabled(scoringTree.scoring)) {
      await initNewCommentsForecast(
        scoringTree.scoring.id,
        scoringTree.nodeDefsObj,
        scoringTree.itemsFather.id,
        currentLocale,
        authentication.user
      )
    }

    await getCommentsListFromStorage(scoringTree.scoring.id, scoringTree.itemsFather.id)
  }

  render() {
    const { scoringTree } = this.props

    function shouldBeDrawn(nodeDefinition) {
      const itemView = scoringTree.itemView.toString()
      const node = scoringTree.scoring.nodes[nodeDefinition.id]

      let toReview = node.to_review
      _.each(nodeDefinition.children_ids, childrenId => {
        const _node = scoringTree.scoring.nodes[childrenId]
        if (_node.to_review === true) {
          toReview = true
        }
      })

      if (
        // Ticket VAV3-286: unscored filter
        itemView === itemViewsMap.unscored.toString() &&
        node.percentageScored < 100
        // End ticket VAV3-286
      ) {
        return true
      }

      if (
        // Ticket VAV3-634: to review filter
        itemView === itemViewsMap.toReview.toString() &&
        toReview === true
        // End ticket VAV3-634
      ) {
        return true
      }

      if (itemView !== itemViewsMap.unscored.toString() && itemView !== itemViewsMap.toReview.toString()) {
        return true
      }

      return false
    }

    function drawItems() {
      const html = []
      for (let i = 0; i < scoringTree.itemsFather.children_ids.length; i += 1) {
        const node = scoringTree.nodeDefsObj[scoringTree.itemsFather.children_ids[i]]
        if (node.type === nodeDefinitionTypeMap.item && node.parent_id === scoringTree.itemsFather.id) {
          if (shouldBeDrawn(node)) {
            html.push(<Item key={i} node={node} />)
          }
        }
      }
      return html
    }

    return (
      <div>
        {Object.keys(scoringTree.itemsFather).length > 0 && (
          <div
            className={classNames({
              sctree_rp: true,
            })}
          >
            <RightPanelHead />
            {scoringTree.itemsFather.id && drawItems()}
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
    device: state.device,
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
  }
}

export default connect(mapStateToProps, actionCreators)(RightPanel)
