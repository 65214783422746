import React from 'react'
import { connect } from 'react-redux'

import * as actionCreators from '../../../../store/actions'
import CloseIcon from '../../../../assets/svg/x-instr.svg'
import BreadcrumbIcon from '../../../../assets/svg/breadcrumb-instr.svg'
import InstructionIcon from '../../../../assets/svg/instruction-icon.svg'
import screenTypesMap from '../../../../layout/_parts/screenTypesMap'
import { createBreadcrumb } from '../../../../../va-corejs-v3/utils'
import { getMediaBlobUrl } from '../../../../../va-corejs-v3/api'

const classNames = require('classnames')

export class Guidelines extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      criterionBreadcrumb: [],
      imageZoomed: false,
      mediaUrl: null,
    }
  }

  componentDidMount = async () => {
    const { nodeDefId, scoringTree, environment } = this.props
    const { nodeDefsObj } = scoringTree
    const breadcrumb = createBreadcrumb(nodeDefsObj, nodeDefId, environment.lang)
    this.setState({ criterionBreadcrumb: breadcrumb })
    await this.setMedia()
  }

  handleZoomImage = e => {
    e.stopPropagation()
    const { imageZoomed } = this.state
    this.setState({ imageZoomed: !imageZoomed })
  }

  extractGuideline = (slug, guidelines) => {
    let guideline = guidelines.filter(item => item.slug === slug)
    guideline = guideline.length === 0 ? false : guideline[0]
    return guideline
  }

  // eslint-disable-next-line consistent-return
  setMedia = async () => {
    const { scoringTree, nodeDefId, environment } = this.props
    const node = scoringTree.nodeDefsObj[nodeDefId]
    let media = false

    if (node.media !== undefined && node.media.length > 0) {
      media = node.media.find(element => {
        return element.collection === `${environment.lang}/instruction`
      })

      if (!media) {
        return false
      }

      this.setState({
        mediaUrl: await getMediaBlobUrl(media.url),
      })
    }
  }

  render() {
    const { imageZoomed } = this.state
    const { device, updateShowGuidelines, updateScrollDisabledStatus } = this.props
    const { criterionBreadcrumb, mediaUrl } = this.state

    const BreadcrumbComponent = () => {
      const html = []
      for (let i = 0; i !== criterionBreadcrumb.length; i += 1) {
        html.push(
          <div
            key={i}
            className={classNames({
              guidelines_breadcrumb_element: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            <span>{criterionBreadcrumb[i]}</span>
            {i !== criterionBreadcrumb.length - 1 && <BreadcrumbIcon />}
          </div>
        )
      }
      return html
    }

    if (imageZoomed) {
      return (
        <div
          className={classNames({
            guidelines_container: true,
          })}
        >
          <div
            className={classNames({
              guidelines_container_inner_container: true,
            })}
          >
            <div
              className={classNames({
                guidelines_container_breadcrumb: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              <BreadcrumbComponent />
            </div>
            <div
              className={classNames({
                guidelines_close_btn: true,
              })}
              role="button"
              tabIndex={0}
              onClick={e => this.handleZoomImage(e)}
              onKeyPress={e => this.handleZoomImage(e)}
            >
              <CloseIcon />
            </div>
            <div className="zoomed_image_title">
              <InstructionIcon />
              <span className="title_text">Instructions</span>
            </div>
            {mediaUrl && (
              <div
                className={classNames({
                  guidelines_container_instruction_img: true,
                  isSafari: device.isSafari,
                  isFirefox: device.isFirefox,
                  desk: device.screenType === screenTypesMap.desk,
                  ipadh: device.screenType === screenTypesMap.iPadH,
                  ipadv: device.screenType === screenTypesMap.iPadV,
                  iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                  iphonese: device.screenType === screenTypesMap.iPhoneSE,
                })}
              >
                <img src={mediaUrl} alt="instructions" />
              </div>
            )}
          </div>
        </div>
      )
    }

    return (
      <div
        className={classNames({
          guidelines_container: true,
        })}
      >
        <div
          className={classNames({
            guidelines_container_inner_container: true,
          })}
        >
          <div
            className={classNames({
              guidelines_container_breadcrumb: true,
              isSafari: device.isSafari,
              isFirefox: device.isFirefox,
              desk: device.screenType === screenTypesMap.desk,
              ipadh: device.screenType === screenTypesMap.iPadH,
              ipadv: device.screenType === screenTypesMap.iPadV,
              iphonexr: device.screenType === screenTypesMap.iPhoneXR,
              iphonese: device.screenType === screenTypesMap.iPhoneSE,
            })}
          >
            <BreadcrumbComponent />
          </div>

          <div
            className={classNames({
              guidelines_close_btn: true,
            })}
            role="button"
            tabIndex={0}
            onClick={e => {
              updateScrollDisabledStatus(false)
              updateShowGuidelines(e, false)
            }}
            onKeyPress={e => {
              updateScrollDisabledStatus(false)
              updateShowGuidelines(e, false)
            }}
          >
            <CloseIcon />
          </div>
          {mediaUrl && (
            <div
              className={classNames({
                guidelines_container_instruction_img: true,
                isSafari: device.isSafari,
                isFirefox: device.isFirefox,
                desk: device.screenType === screenTypesMap.desk,
                ipadh: device.screenType === screenTypesMap.iPadH,
                ipadv: device.screenType === screenTypesMap.iPadV,
                iphonexr: device.screenType === screenTypesMap.iPhoneXR,
                iphonese: device.screenType === screenTypesMap.iPhoneSE,
              })}
            >
              <img src={mediaUrl} alt="instructions" />
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    templates: state.templates,
  }
}

export default connect(mapStateToProps, actionCreators)(Guidelines)
