import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../../store/actions'
import CriteriaCommentsIcon from '../../../../assets/svg/criteria-comments-icon.svg'
import CriteriaMediaIcon from '../../../../assets/svg/criteria-media-icon.svg'
import ExpandIcon from '../../../../assets/svg/expand-icon.svg'
import CollapseIcon from '../../../../assets/svg/collapse-icon.svg'
import InstructionIcon from '../../../../assets/svg/instruction-icon.svg'
import InterRankingIcon from '../../../../assets/svg/inter-ranking-button.svg'
import MandatoryCriterionMarker from '../../../../assets/svg/mandatory-criterion-marker.svg'
import ReviewBtnFlag from '../../../../assets/svg/review-btn-flag.svg'
import SelectedFlagIcon from '../../../../assets/svg/selected-flag-icon.svg'
import ScoreButtons from './scoreButtons'
import MediaButton from './mediaButton'
import MediaGallery from './mediaGallery'
import NodeComments from '../comments/nodeComments'
import Guidelines from './guidelines'
import { setScoreButtonColorStyle } from '../../../../../va-corejs-v3/utils'
import mediaCollectionsMap from '../../../../../va-corejs-v3/utils/mediaCollectionsMap'
import { isProjectModeEnabled, normalizeProjectModeCount } from '../projectMode/utils'

const classNames = require('classnames')

export class Criterion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      forceScoringButtonsVisible: false,
      showGuidelines: false,
      placehodersMediaCount: 0,
    }
  }

  updatePlaceholderMediaCount = placehodersMediaCount => {
    this.setState({ placehodersMediaCount })
  }

  updateForceButtonsVisible = (e, forceScoringButtonsVisible) => {
    e.stopPropagation()
    this.setState({ forceScoringButtonsVisible })
  }

  updateShowGuidelines = (e, showGuidelines) => {
    e.stopPropagation()
    this.setState({ showGuidelines })
  }

  handleUpdateShowInterRanking = (e, criterionInstance) => {
    const { updateShowInterRanking, scoringTree } = this.props
    e.stopPropagation()
    updateShowInterRanking(
      criterionInstance.product_id,
      criterionInstance.node_definition_id,
      criterionInstance.score,
      scoringTree.scoring.template_id,
      true
    )
  }

  handleScoreButtonsClick = () => {
    this.setState({ forceScoringButtonsVisible: false })
  }

  render() {
    const {
      texts,
      device,
      environment,
      criterionIdx,
      nodeDefId,
      scoringTree,
      updateExpandedNodeIntoScoring,
      updateEnableDisableNodes,
      updateScrollDisabledStatus,
      updateToReviewValue,
      authentication,
      processSyncQueue,
      commentFocused,
      updateLockNodeCliksOnCommentFocus,
    } = this.props
    const { forceScoringButtonsVisible, showGuidelines, placehodersMediaCount } = this.state

    const essentialModeEnabled = process.env.essentialModeEnabled ? JSON.parse(process.env.essentialModeEnabled) : false
    const isProjectMode = isProjectModeEnabled(scoringTree.scoring)

    const productIsLocked = scoringTree.scoring.is_locked

    const criterionDef = scoringTree.nodeDefsObj[nodeDefId]
    const criterionInstance = scoringTree.scoring.nodes[nodeDefId]

    // const formattedCommentsCount = !criterionInstance.commentsCount ? 0 : criterionInstance.commentsCount
    const formattedCommentsCount = normalizeProjectModeCount(scoringTree.scoring, nodeDefId, 'comments') || 0
    const formattedMediaCount = normalizeProjectModeCount(scoringTree.scoring, nodeDefId, 'media') || 0

    async function handleCriteriaClicked(e) {
      e.stopPropagation()
      const isChildElement =
        e.target.classList.contains('slider') ||
        e.target.classList.contains('sctree_rp_gallery_show_more_less_btn') ||
        e.target.type === 'checkbox' ||
        e.target.type === 'textarea' ||
        e.target.type === 'text' ||
        e.target.type === 'select-one' ||
        e.target.type === 'file'

      if (!isChildElement) {
        if (commentFocused && commentFocused.status === 1) {
          commentFocused.status = 2
          updateLockNodeCliksOnCommentFocus(commentFocused)
          return
        }
        if (commentFocused && commentFocused.status === 2) {
          commentFocused.status = 0
          updateLockNodeCliksOnCommentFocus(commentFocused)
        }

        await updateExpandedNodeIntoScoring(scoringTree.scoring.id, nodeDefId, scoringTree.itemView)
      }
    }

    async function handleEnableDisableClicked(e) {
      e.stopPropagation()
      const checkValue = e.target.checked
      await updateEnableDisableNodes(scoringTree, criterionDef, checkValue)
      if (device.isOnline) {
        processSyncQueue()
      }
    }

    const renderButtonsCondition = criterionInstance.is_default || forceScoringButtonsVisible

    const criterionDesc = () => {
      if (criterionDef.criterion_template.description) {
        const description = criterionDef.criterion_template.description[environment.lang]
          ? criterionDef.criterion_template.description[environment.lang]
          : criterionDef.criterion_template.description[environment.defaultLang]

        return description
      }
      return ''
    }

    async function handleReviewButtonClicked(e) {
      e.stopPropagation()
      const toReview = criterionInstance.to_review ? !criterionInstance.to_review : true
      await updateToReviewValue(scoringTree.scoring.id, nodeDefId, toReview)
      if (device.isOnline) {
        processSyncQueue()
      }
    }

    const hasInstructionImage = () => {
      if (criterionDef.media !== undefined && criterionDef.media.length > 0) {
        const media = criterionDef.media.find(element => {
          return element.collection === `${environment.lang}/instruction`
        })

        if (media) {
          return true
        }
      }

      return false
    }

    return (
      <div
        className={classNames({
          sctree_rp_criterion: true,
          sctree_rp_criterion_disabled: !criterionInstance.is_enabled,
          sctree_rp_criterion_guideline_open: showGuidelines,
        })}
      >
        <div
          className={classNames({
            sctree_rp_criterion_header: true,
          })}
          role="button"
          tabIndex={0}
          onClick={handleCriteriaClicked}
          onKeyPress={handleCriteriaClicked}
        >
          <div
            className={classNames({
              sctree_rp_criterion_idx: true,
              theme_svg: true,
            })}
          >
            {criterionDef.mandatory && <MandatoryCriterionMarker />}
            {criterionIdx}
          </div>
          <div
            className={classNames({
              sctree_rp_criterion_top_left_cell: true,
            })}
          >
            <div
              className={classNames({
                sctree_rp_criterion_name: true,
              })}
            >
              {criterionDef.name[environment.lang]
                ? criterionDef.name[environment.lang]
                : criterionDef.name[environment.defaultLang]}
              {isProjectMode && <div className="sctree_rp_criterion_type">{texts.score}</div>}
            </div>
            <div
              className={classNames({
                sctree_rp_criterion_description: true,
              })}
            >
              {criterionDesc()}
            </div>
          </div>
          <div
            className={classNames({
              sctree_rp_criterion_top_right_cell: true,
            })}
          >
            {!criterionInstance.isExpanded && criterionInstance.to_review && (
              <div
                className={classNames({
                  sctree_rp_criterion_to_review_flag_container: true,
                })}
              >
                <SelectedFlagIcon />
              </div>
            )}
            <div
              className={classNames({
                sctree_rp_criterion_comments_counter: true,
              })}
            >
              <CriteriaCommentsIcon />
              <div
                className={classNames({
                  sctree_rp_criterion_comments_count: true,
                })}
              >
                {String(formattedCommentsCount)}
              </div>
            </div>
            <div
              className={classNames({
                sctree_rp_criterion_media_counter: true,
              })}
            >
              <CriteriaMediaIcon />
              <div
                className={classNames({
                  sctree_rp_criterion_media_count: true,
                })}
              >
                {String(formattedMediaCount)}
              </div>
            </div>
            <div
              className={classNames({
                sctree_rp_criterion_expand_collapse: true,
              })}
            >
              {criterionInstance.isExpanded ? <CollapseIcon /> : <ExpandIcon />}
            </div>
          </div>
        </div>
        <div
          className={classNames({
            sctree_rp_criterion_score_row: true,
          })}
        >
          {renderButtonsCondition && authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
            <div
              className={classNames({
                sctree_rp_criterion_score_buttons_container: true,
              })}
            >
              <ScoreButtons
                demerit={criterionDef.bonus_demerit}
                criterionInstance={criterionInstance}
                onScoreButtonsClick={this.handleScoreButtonsClick}
              />
            </div>
          )}
          <div
            className={classNames({
              sctree_rp_criterion_right_actions_container: true,
            })}
          >
            {!criterionInstance.is_default && (
              <div
                className={classNames({
                  sctree_rp_criterion_score_value_btn: true,
                  ...setScoreButtonColorStyle(criterionDef.bonus_demerit, criterionInstance.score, true),
                })}
                role="button"
                tabIndex={0}
                onClick={
                  authentication.user.parsedPermissions.canEditProduct && !productIsLocked
                    ? e => {
                        this.updateForceButtonsVisible(e, !forceScoringButtonsVisible)
                      }
                    : null
                }
                onKeyPress={
                  authentication.user.parsedPermissions.canEditProduct && !productIsLocked
                    ? e => {
                        this.updateForceButtonsVisible(e, !forceScoringButtonsVisible)
                      }
                    : null
                }
              >
                {criterionInstance.score}
              </div>
            )}

            {!criterionDef.bonus_demerit &&
              JSON.parse(process.env.interRankingEnabled) === true &&
              !essentialModeEnabled && (
                <div
                  className={classNames({
                    sctree_rp_criterion_inter_ranking_btn: true,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={e => {
                    updateScrollDisabledStatus(true)
                    this.handleUpdateShowInterRanking(e, criterionInstance)
                  }}
                  onKeyPress={e => {
                    updateScrollDisabledStatus(true)
                    this.handleUpdateShowInterRanking(e, criterionInstance)
                  }}
                >
                  <InterRankingIcon />
                </div>
              )}

            {!criterionDef.bonus_demerit && essentialModeEnabled && (
              <div
                className={classNames({
                  sctree_rp_criterion_inter_ranking_btn: true,
                })}
                role="button"
                tabIndex={0}
                onClick={() => {
                  window.location.href = '/previewEssentialInterRanking'
                }}
                onKeyPress={() => {
                  window.location.href = '/previewEssentialInterRanking'
                }}
              >
                <InterRankingIcon />
              </div>
            )}

            {hasInstructionImage() && (
              <div
                className={classNames({
                  sctree_rp_criterion_instruction_btn: true,
                })}
                role="button"
                tabIndex={0}
                onClick={e => {
                  updateScrollDisabledStatus(true)
                  this.updateShowGuidelines(e, true)
                }}
                onKeyPress={e => {
                  updateScrollDisabledStatus(true)
                  this.updateShowGuidelines(e, true)
                }}
              >
                <InstructionIcon />
              </div>
            )}

            {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
              <div
                className={classNames({
                  sctree_rp_criterion_enable_disable_btn_container: true,
                })}
              >
                <label htmlFor={criterionInstance.id} className={classNames({ ios_toggle: true })}>
                  <input
                    id={criterionInstance.id}
                    type="checkbox"
                    checked={criterionInstance.is_enabled}
                    onChange={handleEnableDisableClicked}
                  />
                  <span className={classNames({ slider: true })} />
                </label>
              </div>
            )}
          </div>
        </div>
        {criterionInstance.isExpanded && (
          <div
            className={classNames({
              sctree_rp_exp_area_container: true,
              sctree_rp_criterion_exp_area_container: true,
            })}
          >
            <div
              className={classNames({
                sctree_rp_criterion_exp_area_buttons_container: true,
              })}
            >
              {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
                <div
                  className={classNames({
                    sctree_rp_criterion_to_review_btn: true,
                    sctree_rp_criterion_to_review_btn_selected: criterionInstance.to_review,
                    disabled: !authentication.user.parsedPermissions.canEditProduct,
                  })}
                  role="button"
                  tabIndex={0}
                  onClick={handleReviewButtonClicked}
                  onKeyPress={handleReviewButtonClicked}
                >
                  <ReviewBtnFlag /> <span>{texts.to_review}</span>
                </div>
              )}
              {authentication.user.parsedPermissions.canEditProduct && !productIsLocked && (
                <div
                  className={classNames({
                    sctree_rp_item_node_upload_media_container: true,
                  })}
                >
                  <MediaButton
                    label="Media"
                    mediaType="media"
                    nodeDefId={nodeDefId}
                    updatePlaceholderMediaCount={this.updatePlaceholderMediaCount}
                    collection={mediaCollectionsMap.node_pictures}
                  />
                  <MediaButton
                    label="Video"
                    mediaType="video"
                    nodeDefId={nodeDefId}
                    updatePlaceholderMediaCount={this.updatePlaceholderMediaCount}
                    collection={mediaCollectionsMap.node_pictures}
                  />
                  <MediaButton
                    label="Photo"
                    mediaType="image"
                    nodeDefId={nodeDefId}
                    updatePlaceholderMediaCount={this.updatePlaceholderMediaCount}
                    collection={mediaCollectionsMap.node_pictures}
                  />
                </div>
              )}
            </div>
            <MediaGallery
              nodeDefId={nodeDefId}
              scoringId={scoringTree.scoring.id}
              placehodersMediaCount={placehodersMediaCount}
              collection={mediaCollectionsMap.node_pictures}
            />
            <NodeComments nodeDefId={nodeDefId} productIsLocked={productIsLocked} />
          </div>
        )}
        {showGuidelines && <Guidelines nodeDefId={nodeDefId} updateShowGuidelines={this.updateShowGuidelines} />}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    device: state.device,
    environment: state.environment,
    texts: state.texts.values,
    scoring: state.scoring,
    scoringTree: state.scoringTree,
    authentication: state.authentication,
    commentFocused: state.scoringTree.commentFocused,
  }
}

export default connect(mapStateToProps, actionCreators)(Criterion)
